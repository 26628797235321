import React, { memo, useRef, useState } from 'react';
import { ChangePasswordStyled, Form, InputStyled, Row } from './style.jsx';
import IdentifyyLogo from 'components/common/identifyy_logo';
import Links from '../common/links';
import Column from '../common/column';
import Header from '../common/header';
import SubmitButton from '../common/submit_button';
import { changePasswordRequest } from 'requests/users/devise/change_password';
import { showNotification } from 'components/FlashMessages';
import Captcha from 'components/common/captcha';

const ChangePassword = params => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [passwordConfirmationErrors, setPasswordConfirmationErrors] = useState([]);
  const [captchaToken, setCaptchaToken] = useState('');

  const captchaRef = useRef();

  const onClickSubmit = e => {
    e.preventDefault();

    const user = {
      password: password,
      password_confirmation: passwordConfirmation,
      reset_password_token: new URL(window.location).searchParams.get('reset_password_token'),
    };
    const data = {
      utf8: '✓',
      authentication_token: $("meta[name='csrf-token']").attr('content'),
      commit: 'Change my password',
      user: user,
      captcha_token: captchaToken,
    };

    changePasswordRequest(data)
      .then(response => {})
      .catch(error => {
        showNotification({ level: 'error', message: error?.message || I18n.t('users.accounts.form.errors.notif') });
        captchaRef.current?.reset();
      });
  };

  const onChangePassword = value => {
    if (value.length < 6 && value.length != 0) {
      setPasswordErrors([I18n.t('users.change_password.errors.password_length')]);
    } else {
      setPasswordErrors([]);
    }

    setPassword(value);
  };

  const onChangePasswordConfirmation = value => {
    if (value != password) {
      setPasswordConfirmationErrors([I18n.t('users.change_password.errors.password_confirmation')]);
    } else {
      setPasswordConfirmationErrors([]);
    }

    setPasswordConfirmation(value);
  };

  return (
    <ChangePasswordStyled>
      <Column>
        <IdentifyyLogo />
        <Header>{I18n.t('users.change_password.title')}</Header>
        <Form>
          <InputStyled
            placeholder={I18n.t('users.change_password.new_password')}
            id='password'
            type='password'
            value={password}
            onChange={e => onChangePassword(e.target.value)}
            errors={passwordErrors}
          ></InputStyled>
          <InputStyled
            placeholder={I18n.t('users.change_password.confirm')}
            id='password_confirmation'
            type='password'
            value={passwordConfirmation}
            onChange={e => onChangePasswordConfirmation(e.target.value)}
            errors={passwordConfirmationErrors}
          ></InputStyled>
          {params.captcha && (
            <Row>
              <Captcha onSuccess={setCaptchaToken} ref={captchaRef} />
            </Row>
          )}
          <SubmitButton
            className='primary'
            disabled={!password && !passwordConfirmation}
            type='submit'
            onClick={onClickSubmit}
          >
            {I18n.t('users.change_password.submit')}
          </SubmitButton>
        </Form>
        <Links />
      </Column>
    </ChangePasswordStyled>
  );
};

export default memo(ChangePassword);
